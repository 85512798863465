import styled from 'styled-components'

import { FlagStyled } from '@base/FlagIcon/styles'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-inline-end: 10px;

  svg {
    fill: ${p => p.theme.colors.white};
    height: 16px;
  }

  ${FlagStyled} {
    margin-inline: 7px 19px;
  }
`

export const CurrencyWrapper = styled.div`
  font-size: 14px;
  line-height: 16px;
  display: inline-flex;
  align-items: center;
  column-gap: 10px;

  span {
    color: ${p => p.theme.colors.white};
  }
`
